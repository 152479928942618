.splide {
    padding-top:1rem !important;
    padding-bottom:1rem !important;
}

.splide__pagination {
    bottom: -1em !important;
    left: 50% !important;
}

div.roundplusbtn {
    background: var(--primary) !important;
}
