@keyframes fadein {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* React Transition Group animations */
.slidee-enter {
  transform: translateX(100%);
}
.slidee-enter-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}
.slidee-exit {
  transform: translate(0%, -100%);
}
.slidee-exit-active {
  position: absolute;
  transform: translate(-100%, -100%);
  transition: transform 500ms ease-in-out;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 120ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 120ms;
}